﻿import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import { CompareState } from '@tk/components/tk.compare.button';

export default class TKCompareListLink extends TKCustomElementFactory {
    counter: number;
    link?: HTMLElement;
    emptyClass: string;

    constructor() {
        super();

        this.counter = 0;
        this.link = this.querySelector('a') || undefined;
        this.emptyClass = this.getAttribute('data-tk-compare-list-link-empty-class') || 'tk-button__header--empty';
    }

    connectedCallback(): void {
        if (
            !this.hasAttribute('data-tk-compare-list-link-count')
            || !this.link
        ) return;
        this.initCounter();
    }

    initCounter() {
        const countString = this.getAttribute('data-tk-compare-list-link-count')!;
        this.counter = Number(countString);
        this.setCounter();
    }

    updateCounter(compareState: CompareState) {
        if (compareState === 'a') this.counter += 1;
        if (compareState === 'p') this.counter -= 1;
        this.setCounter();
    }

    setCounter() {
        this.link!.setAttribute('data-count', `${this.counter}`);
        this.updateCounterVisibility();
    }

    updateCounterVisibility() {
        this.link!.classList.toggle(this.emptyClass, this.counter === 0);
    }
}