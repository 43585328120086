﻿/* eslint-disable arrow-body-style */
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

interface ProductVariant {
    id: string;
    element: HTMLElement;
    spec: Record<string, string>;
}

interface FilterOption {
    element?: HTMLSelectElement;
    attr: string;
    options: Set<string>
}

export default class TKProductVariants extends TKCustomElementFactory {
    selectorProductVariant: string = '[data-tk-product-variant]';
    selectorproductVariantFilters: string = '[data-tk-product-variant-filter]';
    selectorProductVariantList: string = '[data-tk-product-variant-list]';
    selectorProductVariantAttribute: string = '[data-tk-product-variant-attribute]';
    selectorProductVariantValue: string = '[data-tk-product-variant-value]';

    productVariantList?: HTMLElement;
    productVariants?: HTMLCollection;

    productVariantsCollection: ProductVariant[];
    filteredCollection: ProductVariant[];
    selectedVariant?: string;
    noVariantFound: string;

    productVariantFilters: NodeListOf<HTMLSelectElement>;

    disableSelectingFirst: boolean;

    filteredOptions: FilterOption[] = [];

    resetButton?: HTMLButtonElement;

    constructor() {
        super();

        this.productVariantList = this.querySelector(this.selectorProductVariantList) || undefined;
        this.productVariantFilters = this.querySelectorAll(this.selectorproductVariantFilters) || undefined;
        this.productVariantsCollection = [];
        this.filteredCollection = [];
        this.noVariantFound = this.getAttribute('data-tk-product-variant-none') || 'NONE';
        this.disableSelectingFirst = this.hasAttribute('data-tk-disable-selecting-first');
        this.resetButton = this.querySelector<HTMLButtonElement>('[data-tk-product-variants-reset]') || undefined;
    }

    connectedCallback(): void {
        this.collectProductVariants();
        this.prepareVariantFilters();
        !this.disableSelectingFirst && this.selectFirstVariantInCollection(this.productVariantsCollection);
        this.registerListener();
    }

    private registerListener(): void {
        if (this.productVariantFilters) {
            this.productVariantFilters.forEach((filter) => {
                const filterListEventHandler = this.filterList.bind(this);
                this.pushListener({ event: 'change', element: filter, action: filterListEventHandler });
            });
        }

        if (this.productVariantsCollection.length > 0) {
            this.productVariantsCollection.forEach((variant) => {
                const getSelectedVariantValueHandler = this.getSelectedVariantValue.bind(this, variant.element);
                this.pushListener({
                    event: 'change',
                    element: variant.element,
                    action: getSelectedVariantValueHandler,
                });
            });
        }

        const handleReset = this.handleReset.bind(this);
        this.resetButton && this.pushListener({
            event: 'click',
            element: this.resetButton,
            action: handleReset,
        });
    }

    private getSelectedVariantValue(variant: HTMLElement): void {
        this.setSelectedVariant(variant.getAttribute('data-tk-product-variant') || '');
    }

    private filterList(event: Event): void {
        const selectedValues = this.getSelectedFilterValues();
        this.filteredCollection = this.productVariantsCollection.filter((element) => {
            return Object.keys(selectedValues).every((key: string) => {
                return selectedValues[key] === element.spec[key];
            });
        }) || [];

        this.updateProductVariantList();
        this.filterOptions(event.currentTarget as HTMLSelectElement);
    }

    private handleReset() {
        this.productVariantsCollection
            .filter((item) => item.id !== 'NONE')
            .forEach((item) => { item.element.hidden = false; });
        this.filteredOptions.forEach((item) => {
            const { element } = item;
            if (!element) return;
            const optionList = Array.from(element.options);
            optionList.forEach((option) => {
                option.hidden = false;
                option.selected = false;
            });
            element.disabled = item.options.size < 2;
            const firstOption = element.options.item(0);
            if (!firstOption) return;
            firstOption.selected = true;
        });
    }

    private filterOptions(target: HTMLSelectElement) {
        const currentDisabledElements = this.filteredOptions
            .filter((item) => item.element?.disabled)
            .map((item) => item.element);
        const filteredList = this.filteredOptions
            .filter((item) => (
                item.element !== target && !currentDisabledElements.includes(item.element)
            ));
        const currentValues = filteredList.map((filterOption) => {
            const values: Set<string> = new Set();
            this.filteredCollection.forEach((item) => {
                values.add(item.spec[filterOption.attr]);
            });

            return { attr: filterOption.attr, values };
        });
        filteredList.forEach((filterOption) => {
            if (!filterOption.element) return;
            const { options } = filterOption.element;
            const optionList = Array.from(options);
            const values = currentValues.find((item) => item.attr === filterOption.attr)?.values;
            if (!values) return;
            optionList.forEach((option) => { option.hidden = false; });
            optionList.filter((option) => option.value !== '').forEach((option) => {
                option.hidden = !values.has(option.value);
            });
            filterOption.element.disabled = optionList.filter((item) => !item.hidden && item.value !== '').length < 2;
        });
    }

    private updateProductVariantList(): void {
        this.productVariantsCollection.forEach((variant) => {
            variant.element.hidden = true;
        });

        this.filteredCollection.forEach((variant) => {
            variant.element.hidden = false;
            if (variant.id === this.noVariantFound) variant.element.hidden = true;
        });

        if (this.filteredCollection.length > 0) {
            !this.disableSelectingFirst && this.selectFirstVariantInCollection(this.filteredCollection);
        } else {
            this.showNoVariantsFoundMessage();
        }
    }

    private selectFirstVariantInCollection(collection: ProductVariant[]): void {
        const hasSelectedElement = collection.some(TKProductVariants.findFirstSelectedElement);
        if (hasSelectedElement) return;

        const input = collection.at(0)?.element.querySelector<HTMLInputElement>('input[type="radio"]');
        if (!input) return;
        input.checked = true;
        this.setSelectedVariant(input.value);
    }

    private static findFirstSelectedElement(variant: ProductVariant): boolean {
        const input = variant.element.querySelector('input');
        if (!input) return false;
        return !input.hidden && input.checked;
    }

    private setSelectedVariant(selectedValue: string): void {
        this.selectedVariant = selectedValue;
    }

    private showNoVariantsFoundMessage(): void {
        const noVariantFound = this.productVariantsCollection.filter((variant) => variant.id === this.noVariantFound);
        if (noVariantFound) noVariantFound[0].element.hidden = false;

        this.setSelectedVariant('');
    }

    private getSelectedFilterValues(): Record<string, string> {
        const selectedValues: Record<string, string> = {};
        this.productVariantFilters.forEach((filter) => {
            if (filter.value !== '') {
                const selected = {
                    attr: filter.getAttribute('data-tk-product-variant-filter') || '',
                    value: filter.value,
                };
                selectedValues[selected.attr] = selected.value;
            }
        });

        return selectedValues;
    }

    private collectProductVariants(): void {
        const variants = this.productVariantList?.querySelectorAll<HTMLElement>(this.selectorProductVariant);

        variants?.forEach((element) => {
            const variant = {
                id: element.getAttribute('data-tk-product-variant') || '',
                element,
                spec: this.getVariantSpecs(element),
            };

            this.productVariantsCollection.push(variant);
        });
    }

    private prepareVariantFilters(): void {
        const filterAttribute = new Set<string>();

        this.productVariantsCollection.forEach((variant) => {
            Object.keys(variant.spec).forEach((key) => filterAttribute.add(key));
        });

        filterAttribute.forEach((attr: string) => {
            // filter undefined or null values
            const distinctOptions = new Set(
                this.productVariantsCollection.map((variant) => variant.spec[attr]).filter(Boolean),
            );
            const sortedOptions = new Set([...distinctOptions].sort());
            const selectElement = (
                this.querySelector<HTMLSelectElement>(`[data-tk-product-variant-filter="${attr}"]`) || undefined
            );

            this.filteredOptions.push({
                element: selectElement,
                attr,
                options: sortedOptions,
            });
        });

        this.filteredOptions.forEach((options) => {
            if (!options.element) return;
            options.element.disabled = options.options.size < 2;

            const optionsHTML = [...options.options].map(
                (option) => `<option value="${option}">${option}</option>`,
            ).join('');

            options.element.insertAdjacentHTML('beforeend', optionsHTML);
        });
    }

    private getVariantSpecs(variant: Element): Record<string, string> {
        const specs = variant.querySelectorAll(this.selectorProductVariantAttribute);
        const specCollection: Record<string, string> = {};

        specs.forEach((elem: Element) => {
            const spec = {
                attr: elem.getAttribute('data-tk-product-variant-attribute') || '',
                value: elem.getAttribute('data-tk-product-variant-value') || '',
            };

            specCollection[spec.attr] = spec.value;
        });

        return specCollection;
    }
}