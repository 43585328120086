﻿import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class TKFavoriteInfo extends TKCustomElementFactory {
    anchor?: HTMLAnchorElement;
    quantity: number;
    isMulti: boolean;
    emptyClassName: string;

    static observedAttributes = ['data-tk-quantity'];

    constructor() {
        super();
        this.anchor = this.querySelector('a') || undefined;
        this.quantity = Number(this.getAttribute('data-tk-quantity'));
        this.isMulti = this.hasAttribute('data-tk-is-multi');
        this.emptyClassName = this.getAttribute('data-tk-empty-class-name') || 'tk-button__header--empty';
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        if (name === 'data-tk-quantity' || !this.anchor) {
            this.anchor?.setAttribute('data-count', newValue);
            this.anchor?.classList.toggle(this.emptyClassName, Number(newValue) === 0);
        }
    }
}