﻿import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import TKCompareListLink from '@tk/components/tk.compare.list.link';
import { fetchRequest, setAsyncUrl } from '@tk/utilities/tk.fetch';
import render from '@tk/utilities/tk.render';
import { NotificationType, sendNotification } from '@tk/utilities/tk.notification';

export enum CompareState {
    ADDED = 'a',
    REMOVED = 'p',
}

export interface ResponseData {
    success: boolean;
    htmlcode: string;
    comparestatus: CompareState;
}

export default class TKCompareButton extends TKCustomElementFactory {
    asyncURL: string;
    type: string;
    button?: HTMLButtonElement;
    compareType: string;
    item: string;
    itemList: NodeListOf<HTMLElement>;
    activeClass: string;
    compareListLink?: TKCompareListLink;
    shouldRemoveAll: boolean;
    wrapper?: HTMLElement;

    constructor() {
        super();

        this.type = 'Compare';
        this.button = this.querySelector('button') || undefined;
        this.item = this.getAttribute('data-tk-compare-item') || '';
        this.compareType = this.getAttribute('data-tk-compare-type') || 'Art';
        this.asyncURL = this.getAttribute('data-tk-async-url') || setAsyncUrl();
        this.activeClass = this.getAttribute('data-tk-compare-active-class') || 'tk-compare--active';
        this.itemList = document.querySelectorAll(`[data-tk-compare-item="${this.item}"]`);
        this.compareListLink = document.querySelector('tk-compare-list-link') || undefined;
        this.shouldRemoveAll = this.hasAttribute('data-tk-should-remove-all');
        this.wrapper = document.querySelector('tk-compare-wrapper') || undefined;
    }

    connectedCallback(): void {
        if (!this.button) throw new Error('Compare: Button is missing!');
        this.registerClickListener();
    }

    registerClickListener() {
        const handleCompare = this.shouldRemoveAll
            ? this.removeAllItems.bind(this)
            : this.addOrRemoveFromCompare.bind(this);
        this.pushListener({ event: 'click', element: this, action: handleCompare });
    }

    addOrRemoveFromCompare() {
        const data = {
            Id: this.item,
            Type: this.type,
            CompareType: this.compareType,
        };

        fetchRequest({
            requestURL: this.asyncURL,
            payload: data,
            resolveHandler: this.handleResponse.bind(this),
        });
    }

    async removeAllItems() {
        this.button!.disabled = true;
        const itemList = document.querySelectorAll('[data-tk-compare-item]');
        const itemSet = new Set<string>();
        itemList.forEach((item) => {
            const id = item.getAttribute('data-tk-compare-item') || '';
            itemSet.add(id);
        });
        const promises: Promise<TKResponse>[] = [];
        itemSet.forEach((id) => {
            promises.push(this.fetch(id));
        });
        try {
            await Promise.all(promises);
            sendNotification(
                NotificationType.SUCCESS,
                window.opacc.tkCompareMessages?.allRemoved.text,
            );
            this.resetPage();
            this.updateItemsCount(true);
        } catch (error) {
            throw new Error(`${error}`);
        }
    }

    resetPage() {
        if (!this.wrapper) return;
        this.wrapper.innerHTML = '';
        this.wrapper.insertAdjacentElement(
            'beforeend',
            render(`
                <div class="tk-message tk-message--fullwidth tk-message--padding-block-unset">
                    <span class="tk-message__label tk-message__label--fullwidth tk-message__label--text-center">
                        ${window.opacc.tkCompareMessages?.empty}
                    </span>
                </div>
            `),
        );

        // remove remove all button
        const removeAllButton = document.querySelector('[data-tk-should-remove-all]');
        if (removeAllButton) removeAllButton.parentElement?.removeChild(removeAllButton);
    }

    fetch(id: string): Promise<TKResponse> {
        const data = {
            Id: id,
            Type: this.type,
            CompareType: this.compareType,
        };
        return new Promise((resolve) => {
            fetchRequest({
                requestURL: this.asyncURL,
                payload: data,
                resolveHandler: (success) => resolve(success),
            });
        });
    }

    handleResponse(response: TKResponse<ResponseData>) {
        if (!response || !response.success) return;
        const json = response.dataAsJson;
        if (!json.success) return;
        const handler: Record<CompareState, () => void> = {
            a: () => this.addActiveClass(),
            p: () => this.remove(),
        };
        handler[json.comparestatus]();
        this.compareListLink?.updateCounter(response.dataAsJson.comparestatus);
    }

    showErrorMessage(error: string) {
        const errorMessage = this.getMessage(error);
        if (errorMessage !== '') sendNotification(NotificationType.ERROR, errorMessage);
    }

    // eslint-disable-next-line class-methods-use-this
    getMessage(error: string) {
        const tmpMessage = document.createElement('div');
        tmpMessage.innerHTML = error;
        const errorMessage = tmpMessage.querySelector<HTMLElement>('[data-tk-compare-failed-text]') || undefined;
        return errorMessage ? errorMessage.innerText : '';
    }

    addActiveClass() {
        this.itemList.forEach((item) => {
            item.classList.add(this.activeClass);
        });
    }

    remove() {
        this.removeActiveClass();
        this.removeItem();
        // update items count
        this.updateItemsCount();
    }

    removeActiveClass() {
        this.itemList.forEach((item) => {
            item.classList.remove(this.activeClass);
        });
    }

    removeItem() {
        const column = this.closest('[data-tk-column]');
        if (!column) return;
        const columnNumber = column.getAttribute('data-tk-column');
        const rows = document.querySelectorAll('[data-tk-row]');
        rows.forEach((row) => {
            const col = row.querySelector(`[data-tk-column="${columnNumber}"]`);
            col?.remove();
        });
    }

    updateItemsCount(preventPageReset = false) {
        const itemsCountElement = document.querySelector('[data-compare-items-count]');
        if (!itemsCountElement) throw new Error('Missing items count element');

        const rowElement = itemsCountElement.querySelector('.tk-compare__row');
        let itemsCount;

        if (rowElement) {
            itemsCount = rowElement.children.length > 0 ? rowElement.children.length - 1 : 0;
            itemsCountElement.setAttribute('data-compare-items-count', itemsCount.toString());
        } else {
            itemsCountElement.setAttribute('data-compare-items-count', '0');
        }

        if ((itemsCount === 0) && !preventPageReset) this.resetPage();
    }
}