﻿import tippy from 'tippy.js';
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class TKTooltip extends TKCustomElementFactory {
    selectorTooltipContent: string = 'data-tk-tooltip-content';
    selectorTooltipOptions: string = 'data-tk-tooltip-options';
    tooltipContent?: string;
    customOptions: string;

    constructor() {
        super();
        this.tooltipContent = this.getAttribute(this.selectorTooltipContent) || undefined;
        this.customOptions = this.getAttribute(this.selectorTooltipOptions) || '{}';

        if (!this.tooltipContent) {
            throw new Error('TK: Missing tooltip content');
        }
    }

    connectedCallback(): void {
        this.initTooltip();
    }

    initTooltip(): void {
        this.customOptions = this.customOptions.replace(/'/g, '"');

        const tooltipOptions = {
            content: this.tooltipContent,
            ...this.defaultTooltipOptions(),
            ...JSON.parse(this.customOptions),
        };

        // @ts-ignore
        tippy(
            this,
            tooltipOptions,
        );
    }

    // eslint-disable-next-line class-methods-use-this
    defaultTooltipOptions(): object {
        return {
            arrow: false,
            theme: 'tk-tooltip',
            allowHTML: true,
            offset: [0, 8],
        };
    }
}