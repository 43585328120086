﻿import { fetchRequest, setAsyncUrl } from '@tk/utilities/tk.fetch';
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import TKArticleList, { PriceData, ResponseData, ResponseItem } from '@tk/components/tk.article.list';

export default class TKPrice extends TKCustomElementFactory {
    isAsyncTile?: boolean;
    articleList?: TKArticleList;
    asyncURL: string;
    source: string;
    service: string;
    catItemId: string;
    articleId: string;
    loadingType: string;
    sort: string;
    key: string;
    asyncTemplate: string;
    isServiceClassName: string;
    isPromotionClassName: string;
    hasStartedSync: boolean = false;

    constructor() {
        super();
        this.isAsyncTile = Boolean(this.closest('tk-product-tile[data-tk-url]'));
        this.articleList = this.closest('tk-article-list') || undefined;
        this.asyncURL = this.getAttribute('datak-tk-async-url') || setAsyncUrl(true);
        this.source = this.getAttribute('data-tk-source') || '';
        this.service = this.getAttribute('data-tk-service') || '';
        this.catItemId = this.getAttribute('data-tk-cat-item-id') || '';
        this.articleId = this.getAttribute('data-tk-article-id') || '';
        this.loadingType = this.getAttribute('data-tk-loading-type') || '';
        this.sort = this.getAttribute('data-tk-sort') || '';
        this.key = this.getAttribute('data-tk-key') || '';
        this.asyncTemplate = this.getAttribute('data-tk-async-template') || '';
        this.isServiceClassName = this.getAttribute('data-tk-is-service-class-name') || 'tk-price--has-service';
        this.isPromotionClassName = this.getAttribute('data-tk-is-promotion-class-name') || 'tk-price--has-promotion';
    }

    connectedCallback(): void {
        if (
            this.articleList
            && customElements.get('tk-article-list')
            && this.loadingType !== '2'
            && !this.isAsyncTile
        ) {
            this.sendDataToArticleList();
        } else {
            this.loadingType === '2'
                ? this.registerHoverListener()
                : this.handlePrice();
        }
    }

    registerHoverListener() {
        const onHover = this.handlePrice.bind(this);
        this.pushListener({ event: 'mouseover', element: this, action: onHover });
    }

    hover() {
        this.hasStartedSync = true;
        this.handlePrice();
    }

    sendDataToArticleList() {
        this.articleList!.addPrice({
            source: this.source,
            service: this.service,
            catItemId: this.catItemId,
            articleId: this.articleId,
            loadingType: this.loadingType,
            sort: this.sort,
            key: this.key,
            asyncTemplate: this.asyncTemplate,
            element: this,
        });
    }

    handlePrice() {
        const items = [];
        items.push({
            source: this.source,
            service: this.service,
            catitemboid: this.catItemId,
            catitemartinternalno: this.articleId,
            loadingtype: this.loadingType,
            sort: this.sort,
            key: this.key,
            template: this.asyncTemplate,
        });
        const data = {
            type: 'price',
            catitems: JSON.stringify({ items }),
        };
        fetchRequest({
            requestURL: this.asyncURL,
            resolveHandler: this.handleResponse.bind(this),
            payload: data,
        });
    }

    handleResponse(response: TKResponse<ResponseData>) {
        if (!response || !response.success) return;
        if (response.dataAsJson.items?.length !== 1) return;
        const list = response.dataAsJson.items;
        const responseItem = list[0];
        const item = {
            source: this.source,
            service: this.service,
            catItemId: this.catItemId,
            articleId: this.articleId,
            loadingType: this.loadingType,
            sort: this.sort,
            key: this.key,
            asyncTemplate: this.asyncTemplate,
            element: undefined,
        } as PriceData;
        this.refreshPrice(item, responseItem);
    }

    handleError(error: string) {
        this.hasStartedSync = false;
        throw new Error(error);
    }

    refreshPrice(item: PriceData, responseItem: ResponseItem) {
        const conditions = [
            {
                condition: responseItem.basispriceisset,
                selector: '[data-tk-price-basic]',
                className: undefined,
            },
            {
                condition: responseItem.servicepriceisset,
                selector: '[data-tk-price-service]',
                className: this.isServiceClassName,
            },
            {
                condition: responseItem.promopriceisset,
                selector: '[data-tk-price-promotion]',
                className: this.isPromotionClassName,
            },
        ];
        const { selector, className } = conditions.find(({ condition }) => condition) || {};
        if (!selector) return;
        const element = this.querySelector(selector);
        if (!responseItem.html) return;
        if (element) {
            element.innerHTML = responseItem.html;
        } else {
            this.innerHTML = responseItem.html;
        }
        className && this.classList.add(className);
    }
}