﻿import { fetchRequest, setAsyncUrl } from '@tk/utilities/tk.fetch';
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import TKPrice from '@tk/components/tk.price';
import TKStock from '@tk/components/tk.stock';

enum ArticleDataType {
    PRICE = 'price',
    STOCK = 'stock',
}

export interface PriceData {
    source: string;
    service: string;
    catItemId: string;
    articleId: string;
    loadingType: string;
    sort: string;
    key: string;
    asyncTemplate: string;
    element?: TKPrice;
}

export interface StockData {
    source: string;
    catItemId: string;
    articleId: string;
    stockType: string;
    stockLocation: string;
    dispo: string;
    dispoDate: string;
    loadingType: string;
    asyncTemplate: string;
    element?: TKStock;
}

export type LoadingType = '1' | '2' | '3';

export interface ResponseItem {
    catitemboid: string;
    catitemartinternalno: string;
    sort: string;
    template: string;
    basispriceisset: boolean;
    promopriceisset: boolean;
    servicepriceisset: boolean;
    loadingtype: LoadingType;
    html?: string;
}

export interface ResponseData {
    items?: ResponseItem[];
}

export default class TKArticleList extends TKCustomElementFactory {
    prices: PriceData[] = [];
    stocks: StockData[] = [];
    asyncURL: string;

    constructor() {
        super();
        this.asyncURL = this.getAttribute('datak-tk-async-url') || setAsyncUrl(true);
    }

    addPrice(item: PriceData) {
        this.prices.push(item);
        this.handlePrice();
    }

    addStock(item: StockData) {
        this.stocks.push(item);
        this.handleStock();
    }

    handlePrice() {
        const elements = this.querySelectorAll('tk-price');
        if (elements.length !== this.prices.length) return;
        fetchRequest({
            requestURL: this.asyncURL,
            resolveHandler: this.refreshPriceData.bind(this),
            payload: this.getPreparedData(ArticleDataType.PRICE),
        });
    }

    handleStock() {
        const elements = this.querySelectorAll('tk-stock');
        if (elements.length !== this.stocks.length) return;
        fetchRequest({
            requestURL: this.asyncURL,
            resolveHandler: this.refreshStockData.bind(this),
            payload: this.getPreparedData(ArticleDataType.STOCK),
        });
    }

    getPreparedData(type: ArticleDataType) {
        return {
            type,
            catitems: this.getListByType(type),
        };
    }

    getListByType(type: ArticleDataType): string {
        const handler = {
            price: () => JSON.stringify({
                items: [...this.prices.map((item) => ({
                    source: item.source,
                    service: item.service,
                    catitemboid: item.catItemId,
                    catitemartinternalno: item.articleId,
                    loadingtype: item.loadingType,
                    sort: item.sort,
                    key: item.key,
                    template: item.asyncTemplate,
                }))],
            }),
            stock: () => JSON.stringify({
                items: [...this.stocks.map((item) => ({
                    source: item.source,
                    catitemboid: item.catItemId,
                    catitemartinternalno: item.articleId,
                    stocktype: item.stockType,
                    stockloc: item.stockLocation,
                    dispo: item.dispo,
                    dispodate: item.dispoDate,
                    loadingtype: item.loadingType,
                    template: item.asyncTemplate,
                }))],
            }),
        };
        return handler[type]();
    }

    refreshPriceData(response: TKResponse<ResponseData>) {
        if (!response || !response.success) return;
        if (response.dataAsJson.items?.length !== this.prices.length) return;
        const list = response.dataAsJson.items;
        this.prices.forEach((item, index) => {
            const responseItem = list[index];
            const { element } = item;
            element && element.refreshPrice(item, responseItem);
        });
    }

    refreshStockData(response: TKResponse<ResponseData>) {
        if (!response || !response.success) return;
        if (response.dataAsJson.items?.length !== this.stocks.length) return;
        const list = response.dataAsJson.items;
        this.stocks.forEach((item, index) => {
            const responseItem = list[index];
            const { element } = item;
            element && element.refreshStock(item, responseItem);
        });
    }
}