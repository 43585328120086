﻿import { fetchRequest, setAsyncUrl } from '@tk/utilities/tk.fetch';
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import TKArticleList, { ResponseData, ResponseItem, StockData } from '@tk/components/tk.article.list';

export default class TKStock extends TKCustomElementFactory {
    articleList?: TKArticleList;
    asyncURL: string;
    source: string;
    catItemId: string;
    articleId: string;
    stockType: string;
    stockLocation: string;
    dispo: string;
    dispoDate: string;
    loadingType: string;
    asyncTemplate: string;
    isOnStockClassName: string;
    isLessStockClassName: string;
    isOutStockClassName: string;
    isNaStockClassName: string;
    isCallStockClassName: string;

    constructor() {
        super();
        this.articleList = this.closest('tk-article-list') || undefined;
        this.asyncURL = this.getAttribute('datak-tk-async-url') || setAsyncUrl(true);
        this.source = this.getAttribute('data-tk-source') || '';
        this.catItemId = this.getAttribute('data-tk-cat-item-id') || '';
        this.articleId = this.getAttribute('data-tk-article-id') || '';
        this.stockType = this.getAttribute('data-tk-stock-type') || '';
        this.stockLocation = this.getAttribute('data-tk-stock-location') || '';
        this.dispo = this.getAttribute('data-tk-dispo') || '';
        this.dispoDate = this.getAttribute('data-tk-dispo-date') || '';
        this.loadingType = this.getAttribute('data-tk-loading-type') || '';
        this.asyncTemplate = this.getAttribute('data-tk-async-template') || '';
        this.isOnStockClassName = this.getAttribute('data-tk-is-on-stock-class-name') || 'tk-stock--on-stock';
        this.isLessStockClassName = this.getAttribute('data-tk-is-less-stock-class-name') || 'tk-stock--less-stock';
        this.isOutStockClassName = this.getAttribute('data-tk-is-out-stock-class-name') || 'tk-stock--out-stock';
        this.isNaStockClassName = this.getAttribute('data-tk-is-na-stock-class-name') || 'tk-stock--na-stock';
        this.isCallStockClassName = this.getAttribute('data-tk-is-call-stock-class-name') || 'tk-stock--call-stock';
    }

    connectedCallback(): void {
        if (this.articleList && customElements.get('tk-article-list')) this.sendDataToArticleList();
        else this.handleStock();
    }

    sendDataToArticleList() {
        this.articleList!.addStock({
            source: this.source,
            catItemId: this.catItemId,
            articleId: this.articleId,
            stockType: this.stockType,
            stockLocation: this.stockLocation,
            dispo: this.dispo,
            dispoDate: this.dispoDate,
            loadingType: this.loadingType,
            asyncTemplate: this.asyncTemplate,
            element: this,
        });
    }

    handleStock() {
        const items = [];
        items.push({
            source: this.source,
            catitemboid: this.catItemId,
            catitemartinternalno: this.articleId,
            stocktype: this.stockType,
            stockloc: this.stockLocation,
            dispo: this.dispo,
            dispodate: this.dispoDate,
            loadingtype: this.loadingType,
            template: this.asyncTemplate,
        });
        const data = {
            type: 'stock',
            catitems: JSON.stringify({ items }),
        };
        fetchRequest({
            requestURL: this.asyncURL,
            payload: data,
            resolveHandler: this.handleResponse.bind(this),
        });
    }

    handleResponse(response: TKResponse<ResponseData>) {
        if (!response || !response.success) return;
        if (response.dataAsJson.items?.length !== 1) return;
        const list = response.dataAsJson.items;
        const responseItem = list[0];
        const item = {
            source: this.source,
            catItemId: this.catItemId,
            articleId: this.articleId,
            stockType: this.stockType,
            stockLocation: this.stockLocation,
            dispo: this.dispo,
            dispoDate: this.dispoDate,
            loadingType: this.loadingType,
            asyncTemplate: this.asyncTemplate,
            element: undefined,
        } as StockData;
        this.refreshStock(item, responseItem);
    }

    refreshStock(item: StockData, responseItem: ResponseItem) {
        const htmlString = responseItem.html;
        if (!htmlString) return;
        const tooltip = this.querySelector('tk-tooltip');
        tooltip && tooltip.remove();
        this.insertAdjacentHTML('beforeend', htmlString);
    }
}