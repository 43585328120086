﻿// @ts-ignore Import module
import { basketMessages, formErrorMessages } from '@tk/utilities/tk.messages';
import { fetchRequest, setAsyncUrl } from '@tk/utilities/tk.fetch';
import TKMinibasket from '@tk/components/tk.minibasket';
import render from '@tk/utilities/tk.render';
import { NotificationType, sendNotification, sendNotificationByItem } from '@tk/utilities/tk.notification';

export enum BasketAction {
    FETCHING = 'fetching',
    REFRESHED_LIST = 'refreshed-list',
    REFRESHED_BASKET = 'refreshed-basket',
}

export interface BasketMessage {
    code: string;
    text: string;
    addition: string;
}

export interface BasketData {
    action: string;
    additionalAttributes: string;
    allMessagesFromPortal: string;
    amount: string;
    currency: string;
    debugMessagesFromPortal: string;
    itemAmount: string;
    itemPrice: string;
    items: string;
    messageObj: BasketMessage;
    quantity: number;
    salDocItemBoId: string;
    shippingAmount: string;
    success: boolean;
    totalAmount: string;
    totalVat: string;
}

interface BasketEvent {
    action: BasketAction;
    data?: BasketData;
}

export default class TKBasketAction {
    private listeners: ((data: BasketEvent) => void)[] = [];

    component: HTMLElement;
    basketList?: HTMLElement;
    totalAmount?: HTMLElement;
    miniBasket?: TKMinibasket;
    componentSelector: string;

    constructor(component: HTMLElement) {
        this.component = component;
        this.componentSelector = `.${this.component.classList.value}`;
        this.basketList = document.querySelector('[data-tk-basket-list]') || undefined;
        this.totalAmount = document.querySelector('[data-tk-basket-total-amount]') || undefined;
        this.miniBasket = document.querySelector('tk-minibasket') || undefined;
    }

    subscribe(listener: (data: BasketEvent) => void) {
        this.listeners.push(listener);
    }

    emit(data: BasketEvent) {
        this.listeners.forEach((listener) => {
            listener(data);
        });
    }

    fetch(checkForReadonlyContext = false) {
        const url = setAsyncUrl(checkForReadonlyContext);
        this.emit({ action: BasketAction.FETCHING });
        fetchRequest({
            requestURL: url,
            resolveHandler: this.refreshResultList.bind(this),
            payload: this.prepareDataSubmission(),
        });
    }

    prepareDataSubmission() {
        const {
            tkAction,
            tkModule,
            tkArtInternalno,
            tkSalDocItemBoId,
            tkSalDocBoId,
            tkAddAllOptions,
            tkVariant,
            tkQuantityId1,
            tkQuantityId2,
            tkQuantityId3,
            tkAsyncTemplate,
            tkRequest,
            tkEncrypted,
        } = this.component.dataset;

        const data: Record<string, string | undefined | null> = {
            type: 'basket',
            action: tkAction,
            module: tkModule,
            originalUrl: window.location.href,
        };

        tkArtInternalno && (data.artInternalNo = tkArtInternalno);
        tkSalDocItemBoId && (data.salDocItemBoId = tkSalDocItemBoId);
        tkSalDocBoId && (data.salDocBoId = tkSalDocBoId);
        tkAddAllOptions && (data.addalloptions = tkAddAllOptions);
        tkVariant && (data.artDimVariantNo = tkVariant);
        tkQuantityId1 && (data.catItemQuantityDim1 = this.getButtonDimValue(tkQuantityId1));
        tkQuantityId2 && (data.catItemQuantityDim2 = this.getButtonDimValue(tkQuantityId2));
        tkQuantityId3 && (data.catItemQuantityDim3 = this.getButtonDimValue(tkQuantityId3));
        tkAsyncTemplate && (data.template = tkAsyncTemplate);
        tkRequest && (data.request = tkRequest);
        tkEncrypted && (data.encrypted = tkEncrypted);

        Object.keys(this.component.dataset)
            .filter((key) => key.startsWith('tkSaldocitem.'))
            .forEach((key) => {
                data[key.substring(2).toLowerCase()] = this.component.dataset[key];
            });

        if (tkAction === 'addall') {
            const quantities = TKBasketAction.getQuantitiesFromPositions();
            quantities !== '{}' && (data.addall = quantities);
        }

        return data;
    }

    static getQuantitiesFromPositions(): string {
        const quantities: Record<string, string> = {};
        const articleList = document.querySelector('[data-tk-basket-add-all-wrapper]');
        const articles = articleList?.querySelectorAll('article');
        articles?.forEach((article) => {
            const id = article.getAttribute('data-tk-article-id');
            if (!id) return;
            const basketButton = article.querySelector('tk-basket-button');
            if (!basketButton) return;
            const dimensions = basketButton.querySelectorAll<HTMLElement>('[data-tk-dimension]:not([hidden])');
            dimensions.forEach((dimension) => {
                const dimensionNumber = dimension.getAttribute('data-tk-dimension') || '';
                const fieldElement = dimension.querySelector<HTMLInputElement>('[data-tk-input-quantity]');
                quantities[`position${id}${dimensionNumber}`] = fieldElement?.value || '0';
            });
        });
        return JSON.stringify(quantities);
    }

    getButtonDimValue(dimNumIdVal: string): string {
        let retVal = '';
        const dim = this.component.querySelector<HTMLElement>(`[data-tk-dim-id="${dimNumIdVal}"]`);
        if (!dim) return retVal;
        const input = dim.querySelector<HTMLInputElement>('[data-tk-input-quantity]');
        if (!input) return retVal;
        let value = Number(input.value);

        const defaultValue = Number(input.min) || 1;

        if (dim.hidden) return retVal;
        if (Number.isNaN(value) || value <= 0) {
            value = defaultValue;
            retVal = String(defaultValue);
        } else {
            retVal = String(value);
        }
        return retVal;
    }

    getButtonDimUnit(dimNumIdVal: string) {
        let unit = '';
        const dim = this.component.querySelector<HTMLElement>(`[data-tk-dim-id="${dimNumIdVal}"]`);
        if (!dim) return unit;
        if (dim.hidden) return unit;
        const unitElement = dim.querySelector<HTMLElement>('[data-tk-basket-dim-unit]');
        if (!unitElement) return unit;
        unit = unitElement.innerText;
        return unit;
    }

    refreshResultList(response: TKResponse<BasketData>) {
        this.emit({
            action: BasketAction.REFRESHED_LIST,
            data: response.dataAsJson,
        });
        if (response.success) {
            this.updateMiniBasket(response.dataAsJson);
            this.updateBasket(response.dataAsJson);
            if (response.dataAsJson.action === 'added') {
                sendNotificationByItem(this.getQuantityMessage());
            }
        } else {
            response.messages?.forEach((message) => {
                sendNotification(NotificationType.ERROR, message.text);
            });
        }
    }

    updateMiniBasket(data: BasketData) {
        this.miniBasket?.dispatchEvent(this.miniBasket.updateEvent);
        this.miniBasket?.setAttribute('data-count', `${data.quantity}`);
    }

    updateBasket(data: BasketData) {
        if (this.basketList) {
            const { tkAction } = this.component.dataset;
            if (tkAction === 'delete' || tkAction === 'deleteall') {
                if (data.quantity === 0) {
                    window.location.reload();

                    // if quickorder activated, refresh page
                    if (this.basketList.dataset.tkQuickorder === 'true') {
                        window.location.reload();
                    } else {
                        const emptyInfo = document.createElement('div');
                        emptyInfo.classList.add('tk-message', 'tk-message--info');
                        emptyInfo.innerHTML = basketMessages.messageEmptyBasket.text;
                        this.basketList?.parentNode?.replaceChild(emptyInfo, this.basketList);
                    }
                } else {
                    const basketPos = (
                        this.basketList.querySelector(`[data-tk-internalno="${this.component.dataset.tkInternalno}"]`)
                        || this.component.closest('[data-tk-basket-item]')
                    );
                    if (basketPos) {
                        basketPos.remove();
                    }
                }
            } else if (tkAction === 'change') {
                // update item price and amount
                const price = this.component.closest('[data-tk-basket-item]')
                    ?.querySelector('[data-tk-basket-item-price]');
                if (price) {
                    price.innerHTML = `${data.currency} ${data.itemPrice}`;
                }
                const amount = this.component.closest('[data-tk-basket-item]')
                    ?.querySelector('[data-tk-basket-item-amount]');
                if (!amount) return;
                amount.innerHTML = `${data.currency} ${data.itemAmount}`;
            } else if (this.component.closest('tk-quick-order')) {
                // update after quickorder
                const quickorder = this.component.closest<HTMLElement>('tk-quick-order');
                if (!quickorder) return;
                fetchRequest({
                    requestURL: quickorder.dataset.tkUrlRefresh || window.location.href,
                    resolveHandler: this.refreshBasketList.bind(this),
                });
            }
            // update total amount and VAT
            if (this.totalAmount) {
                this.totalAmount.innerHTML = `${data.currency} ${data.totalAmount}`;
            }
            const totalVat = this.basketList.querySelector('[data-tk-basket-total-vat]');
            if (totalVat) {
                totalVat.innerHTML = `${data.currency} ${data.totalVat}`;
            }
        } else if (this.component.closest('tk-quick-order')) {
            // refresh after quickorder
            const quickorder = this.component.closest<HTMLElement>('tk-quick-order');
            if (
                quickorder
                && quickorder.dataset.tkRefresh === 'true'
                && quickorder.dataset.tkUrlRefresh
            ) {
                window.location.href = quickorder.dataset.tkUrlRefresh;
            }
        }

        this.emit({ action: BasketAction.REFRESHED_BASKET });
    }

    refreshBasketList(response: TKResponse) {
        if (!response || !response.success || !this.basketList) return;

        const html = render(response.dataAsHtml);
        const basketListWrapper = html.querySelector('[data-tk-basket-list]');
        basketListWrapper && (this.basketList.innerHTML = basketListWrapper.innerHTML);
    }

    quantityChanger(event: KeyboardEvent) {
        const target = event.target as HTMLElement;
        if (!target || !target.parentElement) return;
        const decrementButton = this.component.querySelector('[data-tk-counter-decreaser]');
        const incrementButton = this.component.querySelector('[data-tk-counter-increaser]');
        const inputField = this.component.querySelector<HTMLInputElement>('[data-tk-input-quantity]');
        if (!inputField) return;
        const step = Number(inputField.getAttribute('step') || 1);
        const min = Number(inputField.getAttribute('min') || step);
        const max = Number(inputField.getAttribute('max') || 0);
        const isMaxSet = max > 0;
        const { tkArtDimCheckCd } = inputField.dataset;
        const artDimCheck = tkArtDimCheckCd ? Number(tkArtDimCheckCd) : 0;
        const oldValue = Number(inputField.value || 0);
        let hasChanged = false;
        let operator = '+';

        if (incrementButton && target === incrementButton) {
            // Wert erhoehen
            operator = '+';
            if (oldValue < min) { operator = '-'; }
        } else if (decrementButton && target === decrementButton) {
            // Wert verringern
            operator = '-';
            if (oldValue > max && isMaxSet) {
                operator = '+';
            }
        }

        // Enter-Taste bet�tigen
        if (event.key === 'ENTER') {
            if (oldValue > max) {
                operator = '+';
            } else if (oldValue < min) {
                operator = '-';
            }
        }
        if (artDimCheck > 0) {
            const multiplikator = TKBasketAction.countDecimals(step);
            const currentValPow = oldValue * 10 ** multiplikator;
            const stepPow = step * 10 ** multiplikator;
            if ((currentValPow === 0 || (currentValPow % stepPow !== 0)) && oldValue >= min) {
                const result = (currentValPow + stepPow - (currentValPow % stepPow)) / 10 ** multiplikator;
                sendNotification(
                    NotificationType.ERROR,
                    formErrorMessages.stepMismatch
                        .replace('{currentValue}', String(oldValue))
                        .replace('{newValue}', String(result))
                        .replace('{step}', String(step)),
                );
                operator = '+';
            }
        }

        hasChanged = TKBasketAction.setCalculatedQuantityField(oldValue, inputField, step, min, max, operator);
        return hasChanged;
    }

    getQuantityMessage() {
        const message = { ...basketMessages.messageAddedToBasket };
        const { tkQuantityId1, tkQuantityId2, tkQuantityId3 } = this.component.dataset;
        const dim1Value = this.getButtonDimValue(tkQuantityId1 || '');
        const dim1Unit = this.getButtonDimUnit(tkQuantityId1 || '');
        const dim2Value = this.getButtonDimValue(tkQuantityId2 || '');
        const dim2Unit = this.getButtonDimUnit(tkQuantityId2 || '');
        const dim3Value = this.getButtonDimValue(tkQuantityId3 || '');
        const dim3Unit = this.getButtonDimUnit(tkQuantityId3 || '');

        let quantity = `${dim1Value} ${dim1Unit} `;
        if (dim2Value) {
            quantity = `${quantity}x ${dim2Value} ${dim2Unit} `;
        }
        if (dim3Value) {
            quantity = `${quantity}x ${dim3Value} ${dim3Unit} `;
        }
        message.text = quantity + basketMessages.messageAddedToBasket.text;
        return message;
    }

    static setCalculatedQuantityField(
        currentVal: number,
        fieldElement: HTMLInputElement,
        step: number,
        min: number,
        max: number = 0,
        operator: string = '+',
    ) {
        const multiplikator = TKBasketAction.countDecimals(step);
        const currentValPow = currentVal * 10 ** multiplikator;
        const stepPow = step * 10 ** multiplikator;
        const minPow = min * 10 ** multiplikator;
        const maxPow = max * 10 ** multiplikator;
        let newValuePow = currentValPow;

        const artDimCheck = Number(fieldElement.dataset.tkArtdimcheckcd);
        const operatorHandler: Record<string, () => number> = {
            '-': () => TKBasketAction.calcMinus(currentVal, newValuePow, stepPow, minPow, min, Number(artDimCheck)),
            '+': () => TKBasketAction.calcPlus(currentVal, newValuePow, stepPow, maxPow, max, Number(artDimCheck)),
        };

        newValuePow = operatorHandler[operator]();

        // Wert veraendert und Step beruecksichtigt?

        if (newValuePow !== currentValPow) {
            if (newValuePow % stepPow === 0 || artDimCheck === 0) {
                fieldElement.value = String(newValuePow / 10 ** multiplikator);
                return true;
            }
            newValuePow = currentValPow;
            sendNotification(
                NotificationType.ERROR,
                formErrorMessages.stepMismatch.replace('{step}', String(step)),
            );
        }
        return false;
    }

    static calcPlus(
        currentValPow: number,
        newValuePow: number,
        stepPow: number,
        maxPow: number,
        max: number,
        artDimCheck: number,
    ) {
        if (maxPow > 0 && newValuePow > maxPow) {
            sendNotification(
                NotificationType.ERROR,
                formErrorMessages.rangeOverflow.replace('{max}', String(max)),
            );
            return maxPow;
        }
        return (currentValPow + stepPow - (artDimCheck > 0 ? (currentValPow % stepPow) : 0));
    }

    static calcMinus(
        currentValPow: number,
        newValuePow: number,
        stepPow: number,
        minPow: number,
        min: number,
        artDimCheck: number,
    ) {
        if (newValuePow < minPow) {
            sendNotification(
                NotificationType.ERROR,
                formErrorMessages.rangeOverflow.replace('{min}', String(min)),
            );
            return minPow;
        }
        return (
            currentValPow - (
                artDimCheck === 0 || currentValPow % stepPow === 0
                    ? stepPow
                    : currentValPow % stepPow
            )
        );
    }

    static countDecimals(value: number) {
        if (Math.floor(value) === value) return 0;
        return value.toString().split('.')[1].length || 0;
    }
}