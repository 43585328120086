﻿import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class TKSelection extends TKCustomElementFactory {
    targetElement?: HTMLInputElement;
    toggleWrapper?: HTMLElement;
    selectWrapper?: HTMLElement;
    maxVisible: number;
    loadedClassName: string;

    constructor() {
        super();

        this.targetElement = this.querySelector('input[type="hidden"]') || undefined;
        this.toggleWrapper = this.querySelector('[data-tk-selection-toggles]') || undefined;
        this.selectWrapper = this.querySelector('[data-tk-selection-select]') || undefined;
        this.maxVisible = Number(this.getAttribute('data-tk-max-visible')) || 3;
        this.loadedClassName = this.getAttribute('data-tk-loaded-class-name') || 'tk-selection--loaded';
    }

    connectedCallback(): void {
        if (
            !this.targetElement
            || !this.toggleWrapper
            || !this.selectWrapper
        ) throw new Error('Selection: Elements are missing!');
        this.registerInputListener();
        this.switchView();
        this.classList.add(this.loadedClassName);
    }

    registerInputListener() {
        const fieldElements = this.toggleWrapper!.querySelectorAll('input');
        const selectElement = this.selectWrapper!.querySelector('select');
        const onInput = this.syncTargetElement.bind(this);
        fieldElements.forEach((fieldElement) => {
            this.pushListener({ event: 'input', element: fieldElement, action: onInput });
        });
        selectElement && this.pushListener({ event: 'input', element: selectElement, action: onInput });
    }

    syncTargetElement(event: KeyboardEvent) {
        const target = event.target as HTMLInputElement | HTMLSelectElement;
        const selectedTarget = target instanceof HTMLInputElement
            ? target
            : target.options[target.selectedIndex];
        const { dataset } = selectedTarget;

        this.targetElement!.value = selectedTarget.value;

        Object.keys(this.targetElement!.dataset).forEach((key) => {
            delete this.targetElement!.dataset[key];
        });

        Object.keys(dataset).forEach((key) => {
            this.targetElement!.dataset[key] = dataset[key];
        });

        this.targetElement?.dispatchEvent(new Event('change'));
    }

    switchView() {
        if (this.toggleWrapper!.childElementCount > this.maxVisible) {
            this.toggleWrapper!.hidden = true;
            this.selectWrapper!.hidden = false;
        } else {
            this.toggleWrapper!.hidden = false;
            this.selectWrapper!.hidden = true;
        }
    }
}